import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';


function ChooseType({ methodTypes: { withdrawal } }) {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-center text-2xl sm:text-3xl md:text-4xl text-primary my-4">
        {t(`cashier:${withdrawal ? 'depositOrWithdrawalText' : 'firstDepositText'}`)}
      </p>
      <div className="flex justify-evenly items-center">
        <Link to="/cashier/deposit/" className="button primary">{t('cashier:depositTitle')}</Link>
        {withdrawal && <Link to="/cashier/withdrawal/" className="button secondary">{t('cashier:withdrawTitle')}</Link>}
      </div>
    </>
  );
}

export default ChooseType;